import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ReactTooltip from "react-tooltip";

import { AppWrap, MotionWrap } from "../../wrapper";
// import { client, urlFor } from "../../client";
import "./Skills.scss";
import { images } from "../../constants";

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState();

  const skillVariants = {
    view: {
      x: [-15, 0],
      opacity: [0, 1],
      transition: {
        x: {
          type: "spring",
          stiffness: "10",
        },
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    hover: {
      y: -7,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    tap: {
      y: -7,
      scale: 1.05,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  };

  const skillsData = [
    { name: "html", icon: images.html, bgColor: "rgb(239 240 255)" },
    { name: "css", icon: images.css, bgColor: "rgb(239 240 255)" },
    { name: "javascript", icon: images.javascript, bgColor: "rgb(239 240 255)" },
    { name: "reactJs", icon: images.react, bgColor: "rgb(239 240 255)" },
    { name: "vueJs", icon: images.vue, bgColor: "rgb(239 240 255)" },
    { name: "Flutter", icon: images.flutter, bgColor: "rgb(239 240 255)" },
    { name: "nodeJs", icon: images.node, bgColor: "rgb(239 240 255)" },
    { name: "MU5", icon: images.mu5, bgColor: "rgb(239 240 255)" },
    { name: "git", icon: images.git, bgColor: "rgb(239 240 255)" },
    {name:"angular", icon:"https://upload.wikimedia.org/wikipedia/commons/c/cf/Angular_full_color_logo.svg",bgColor: "rgb(239 240 255)"},
    { name: "reactNative", icon: images.react, bgColor: "rgb(239 240 255)" },
    {name: "laravel", icon:"https://upload.wikimedia.org/wikipedia/commons/9/9a/Laravel.svg", bgColor: "rgb(239 240 255)"}
  ];

  const experienceData = [
    // {
    //   year:"2021-2021",
    //   works:[
    //     {
    //       year: "2022/07/01",
    //       name: "Piscine 1337 2021",
    //       company: "1337 ",
    //       desc: "script shell , langague c",
    //       duration:"1 month",
    //       imageCompany:images.img1337
    //     }
    //   ]
    // },
    {
      year:"2021-2021",
      works:[
        {
          year: "2022/07/01",
          name: "Full stack Developer (VueJs -PHP)",
          company: "Energidein",
          desc: "full stack developer",
          duration:"2 month",
          imageCompany:images.energidien
        }
      ]
    }

    ,
    {
      year:"2021-2023",
      works:[
        {
          year: "2022/07/01",
          name: "web developpement & mobile",
          company: "Youcode",
          desc: "full stack developer",
          duration:"2 year",
          imageCompany:images.youcode
        }
      ]
    }
  ];

  useEffect(() => {
    const skillsQuery = '*[_type=="skills"] | order(proficiency desc)';
    const experienceQuery = '*[_type=="experiences"] | order(year desc)';
    // client.fetch(skillsQuery).then((skillsData) => {
    setSkills(skillsData);
    // });
    // client.fetch(experienceQuery).then((experienceData) => {
    setExperience(experienceData);
    // });
  }, []);
  return (
    <>
      <h2 className="head-text">
        Skills <span>&</span> Experience
      </h2>
      <div className="app__skills-container">
        <motion.div className="app__skills-list">
          {skills.map((skill, index) => (
            <motion.div
              variants={skillVariants}
              whileInView="view"
              whileHover="hover"
              whileTap="tap"
              className="app__skills-item app__flex"
              key={skill.name + "-" + index}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={skill.icon} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>
        <motion.div className="app__skills-exp">
          {experience?.map((exp) => (
            <motion.div className="app__skills-exp-item" key={exp.year}>
              <div className="app__skills-exp-year">
                <p className="bold-text">{exp.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {exp?.works?.map((work) => (
                  <React.Fragment key={work.name}>
                    <motion.div
                      whileInView={{
                        opacity: [0, 1],
                        x: [-100, 5, 0],
                      }}
                      transition={{
                        duration: 1,
                      }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                    >
                      <h2 className="bold-text">{work.name}</h2>
                      <img className="img-exp" src={work.imageCompany} alt="email" height={"50"} />
                      <p className="p-text">{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#313bac"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </React.Fragment>
                ))}
              </motion.div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, "app__skills"),
  "skills",
  "app__whitebg"
);
