import React from 'react'
import { motion } from 'framer-motion'
import { images } from '../../constants'
import './Whatsapp.scss'

const headerSkillVariants = {
  visible: {
    rotate: [-120, 0],
    y: -10,
    x: 10,
    transition: {
      rotate: { delay: 0.5, duration: 0.3, ease: 'easeOut' },
      y: {
        repeat: Infinity,
        repeatType: 'reverse',
        duration: 2,
        ease: 'easeOut'
      },
      x: {
        repeat: Infinity,
        repeatType: 'mirror',
        duration: 5,
        ease: 'easeOut'
      }
    }
  },
  hover: {
    boxShadow: '0 0 20px rgba(0,0,0,0.2)'
  }
}
function Whatsapp () {
  return (
    <div className='whatsapp-icon'>
    
    <a href='https://wa.me/+212650043210' target="_blank" rel="noreferrer" >
      <motion.div
        variants={headerSkillVariants}
        whileInView='visible'
        whileHover='hover'
        drag
        dragConstraints={{ left: 10, right: 10, top: 10, bottom: 10 }}
        dragElastic={0.1}
        className='circle-cmp app__flex'
        key={1}
      >
        <img height={80} src={images.whatsapp} alt='whatsapp' />
      </motion.div>
    </a>
    </div>
  )
}

export default Whatsapp
