import React, { useState,useRef } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import emailjs from '@emailjs/browser'

import "./Contact.scss";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {

  const form = useRef();
  
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setIsLoading] = useState(false);


 

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    emailjs.sendForm('service_nnzaeka', 'template_2gc0jui', form.current, '3370c4YfoWRVxveAW')
      .then((result) => {
          console.log(result.text);
          setIsFormSubmitted(true)
          setIsLoading(false);
          toast.success(`message send , thank you ${form.current.to_name.value}` , {
            position: toast.POSITION.BOTTOM_CENTER
          });
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <>
     <ToastContainer autoClose={8000}/>
      <h2 className="head-text">
        Contact <span></span>  <span>me</span>
      </h2>

     

      <div className="app__contact-cards">
        <a  href="mailto:sabirkhaloufi@gmail.com" className="app__contact-card">
          <img src={images.email} alt="email" />
          <div  className="p-text">
            sabirkhaloufi@gmail.com
          </div>
        </a>
        <a href="https://wa.me/+212650043210" className="app__contact-card">
          <img src={images.whatsapp} alt="mobile" />
          <div className="p-text">
            +212650043210
          </div>
        </a>
      </div>
      {!isFormSubmitted ? (
        <form ref={form} onSubmit={sendEmail} className="app__contact-form app__flex">
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Your Name"
              name="to_name"
              required
            />
          </div>
          <div className="app__flex">
            <input
              type="email"
              className="p-text"
              placeholder="Your Email"
              name="from_name"
              required
            />
          </div>
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Subject"
              name="subject"
              required
            />
          </div>
          <div>
            <textarea
              name="message"
              placeholder="Your Message"
              required
            />
          </div>

          {loading ? <input
          disabled
            type="submit"
            className=" portfolio-button"
            value="Sending Message..."
          />:<input
          type="submit"
          className=" portfolio-button"
          value={loading ? "Sending Message..." : "Send Message"}
        />}
          
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in <span>Touch!</span>
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Contact, "app__contact"),
  "contact",
  "app__whitebg"
);
