import React, { useState, useRef } from "react";
import ReactStars from "react-rating-stars-component";

import { AppWrap, MotionWrap } from "../../wrapper";

import "./Addfedback.scss";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sanityClient from "../../client";
import axios from "axios";

const Addfedback = () => {
  const form = useRef();

  const [start, setStarts] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    if (start) {
      console.log("starts vide");
    }
    // setIsLoading(true);
    // console.log(start);

    const postData = {
      _type: "document",
      Name: form.current[0].value,
      Feedback: form.current[1].value,
      StarRating: start,
      Status: "no",
    };

    const apiUrl = `https://45y4w8yv.api.sanity.io/v1/data/mutate/production`;

    // Headers with authentication token
    const headers = {
      Authorization: `Bearer skOfL7b57w08n4Y1bsrUj704PAqfnFPmUlLAWUR8kgTuEq6aJ6x8xoeafP3G0VqQ0LXFi96fJVpSDIcuMwsgxhwarGvb5JGxri5NkBgIFnvLF5v6BsBBu9nHlAg588BB5OFGNrrzqQsT68dmeZag2qTD2SBjWuNaY5B7uts0A1ufw0Sq1wlN`,
      "Content-Type": "application/json",
    };

    axios
      .post(apiUrl, postData, { headers })
      .then((response) => {
        console.log("Successfully created data:", response.data);
      })
      .catch((error) => {
        console.error("Error creating data:", error);
      });

    // try {
    //   const response = await sanityClient.create({
    //     _type: "feedback",
    //     name: form.current[0].value,
    //     feedback: form.current[1].value,
    //     starts: start,
    //     status: "no",
    //   });
    //   console.log(response);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const ratingChanged = (newRating) => {
    setStarts(newRating);
  };

  return (
    <>
      <ToastContainer autoClose={8000} />
      <h2 className="head-text">
        Add <span></span> <span>Fedback</span>
      </h2>

      <div>
        <ReactStars
          count={5}
          onChange={ratingChanged}
          size={50}
          isHalf={true}
          emptyIcon={<i className="far fa-star"></i>}
          halfIcon={<i className="fa fa-star-half-alt"></i>}
          fullIcon={<i className="fa fa-star"></i>}
          activeColor="#ffd700"
        />
      </div>
      {!isFormSubmitted ? (
        <form
          ref={form}
          onSubmit={sendEmail}
          className="app__contact-form app__flex"
        >
          <div className="app__flex">
            <input
              type="text"
              className="p-text"
              placeholder="Your Name"
              name="name"
              required
            />
          </div>

          <div>
            <textarea name="feedback" placeholder="Your fedback" required />
          </div>
          {loading ? (
            <input
              disabled
              type="submit"
              className=" portfolio-button"
              value="Sending Message..."
            />
          ) : (
            <input
              type="submit"
              className=" portfolio-button"
              value={loading ? "Sending..." : "Add Fedback"}
            />
          )}
        </form>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in <span>Touch!</span>
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Addfedback, "app__contact"),
  "contact",
  "app__whitebg"
);
