import React from "react";
import Header from "./Header/Header";
import Work from "./Work/Work.jsx";
import About from "./About/About";
import Skills from "./Skills/Skills";
import Testimonial from "./Testimonial/Testimonial";
import Contact from "./Contact/Contact";
import { Navbar, Footer } from "../components";
import "../App.scss";
import Whatsapp from "../components/buttonwhatsapp/Whatsapp";
import Certificats from "./Certificats/Certificats";

function Home() {
  return (
    <div>
        <Whatsapp />
        <Navbar />
        <Header />
        <About />
        <Certificats />
        <Skills />
        <Work />
        {/* <Testimonial /> */}
        <Contact />
        <Footer />
    </div>
  );
}

export default Home;
