import React from "react";

import { BsLinkedin, BsInstagram, BsGithub, BsTwitter, BsYoutube} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <a
          href="https://www.linkedin.com/in/sabir-lkhaloufi-9aaab2209/"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsLinkedin />
        </a>
      </div>
     
      <div>
        <a
          href="https://github.com/sabirlkhaloufi"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsGithub />
        </a>
      </div>
      <div>
        <a
          href="https://www.youtube.com/@sabirkhaloufi2364"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsYoutube />
        </a>
      </div>
      <div>
        <a
          href="https://twitter.com/LkhaloufiSabir"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsTwitter />
        </a>
      </div>
      <div>
        <a
          href="https://web.facebook.com/sabirlkhaloufi"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <FaFacebookF />
        </a>
      </div>
      <div>
        <a
          href="https://www.instagram.com/sabirkhaloufi/"
          target="_blank"
          rel="noreferrer"
          className="app__flex"
        >
          <BsInstagram />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
