import React from 'react'
import './Card.scss'
import { AiFillEye } from 'react-icons/ai'

function Card({title , img, link}) {
  return (
    


<div className="drop" style={{color: '#FF0F5B'}}>
    <div className="content">
      <img src={img} alt={title}/>
      <p><b>{title}</b> </p>
      <a
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  className={`app__flex ${
                    link.toLowerCase() === 'na' ? 'none' : ''
                  }`}
                >
                                      <AiFillEye />
 </a>
    </div>
  </div>
  )
}

export default Card