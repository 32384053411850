import React, { useState, useEffect } from 'react'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper'
import AllCertificats from './Data'


import './Certificats.scss'
import Card from './Card/Card'

const Certificats = () => {
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
  const [certificats, setcertificats] = useState([])

  const [touched, setTouched] = useState(null)

  useEffect(() => {
    setcertificats(AllCertificats)
  }, [])

 

  return (
    <>
    <h2 className='head-text'>
     <span>Certificats</span> 
    </h2>

    

    <motion.div
      animate={animateCard}
      transition={{ duration: 0.5, delayChildren: 0.5 }}
      className='app__work-portfolio'
      onClick={() => setTouched(null)}
    >
      {certificats.map((cert, index) => (
        <motion.div
          className={`container`}
          key={index}
          onClick={() => setTouched(index)}
          onTouchStart={() => setTouched(index)}
        >

          <Card title={cert.title} img={cert.image} link={cert.link}/>
          
        </motion.div>
      ))}
    </motion.div>
  </>
  )
}

export default AppWrap(MotionWrap(Certificats, 'app__works'), 'work', 'app__primarybg')
