import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { AppWrap, MotionWrap } from "../../wrapper";
// import { client, urlFor } from "../../client";
import "./Testimonial.scss";
import sanityClient from "../../client";




const Testimonial = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);


  
  useEffect(() => {
		sanityClient
			.fetch(
				`*[_type == "feedback"]`
			)
			.then((data) =>  console.log(data)
     )
			.catch(console.error);
	}, []);


  const testimonialsData = [{
    name:"Sabir Lkhaloufi", company:"ddd", feedback:"sabir is a full stack developer kdksjdlksdlskjdlsdskdj" ,imgUrl:"https://www.denofgeek.com/wp-content/uploads/2022/05/Leged-of-Zelda-Link.jpg?resize=768%2C432"
  },{
    name:"eee", company:"ddd", feedback:"dddddddddddddddddddd",imgUrl:"https://www.denofgeek.com/wp-content/uploads/2022/05/Leged-of-Zelda-Link.jpg?resize=768%2C432"
  },{
    name:"eqqqqqqee", company:"ddd", feedback:"dddddddddddddddddddd",imgUrl:"https://www.denofgeek.com/wp-content/uploads/2022/05/Leged-of-Zelda-Link.jpg?resize=768%2C432"
  }]
  const brandsData = [{_id:1,imgUrl:"https://www.denofgeek.com/wp-content/uploads/2022/05/Leged-of-Zelda-Link.jpg?resize=768%2C432", name:"aicha el kanfaoui"}];
  useEffect(() => {
    // client.fetch(testimonialsQuery).then((testimonialsData) => {
      setTestimonials(testimonialsData);
    // });

    // client.fetch(brandsQuery).then((brandsData) => {
      setBrands(brandsData);
    // });
  }, []);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  const test = testimonials[currentIndex];

  return (
    <>
      <h2 className="head-text">
        See what <span>Others</span> say about me!
      </h2>
      {testimonials.length && (
        <>
          <div className="app__testimonial-carousel app__flex">
            <img src="https://www.denofgeek.com/wp-content/uploads/2022/05/Leged-of-Zelda-Link.jpg?resize=768%2C432" alt={test.name} />
            <div className="app__testimonial-content">
              <p className="p-text">{test.feedback}</p>
              <div>
                <h4 className="bold-text">{test.name}</h4>
                <h5 className="p-text">{test.company}</h5>
              </div>
            </div>
          </div>

          <div className="app__testimonials-btns app__flex">
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft />
            </div>
            <div
              className="app__flex"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight />
            </div>
          </div>
        </>
      )}

      {/* <div className="app__testimonials-brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand._id}
          >
            <img src={brand.imgUrl} alt={brand.name} />
          </motion.div>
        ))}
      </div> */}
    </>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, "app__testimonial"),
  "testimonials",
  "app__primarybg"
);
