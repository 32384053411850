const urlPro = 'https://sabirsoft.com/'
const urlDev = 'http://localhost:3001/'
const  Works = [
    {
      image: `${urlPro}assets/khadamat.png`,
      title: `Khadamat Maroc`,
      description: `HTML CSS BOOTSRAP JAVASCRIPT PHP SQL`,
      Demo: `https://www.youtube.com/watch?v=dgT61PlID64&t=12s`,
      LienGithub: `https://github.com/sabirlkhaloufi/project-khadamat`,
      categories:[`Web Projects`],
      technologies: [`html`,'css','bootstrap','javascript','php','mvc','sql','axios']
    },
    {
      image: `${urlPro}assets/pharmacie.jpeg`,
      title: `pharmacie de garde`,
      description: `ReactJs NodeJs ReactNative Express `,
      Demo: `https://www.youtube.com/watch?v=u0wAgWxl52E&t=1s`,
      LienGithub: `https://www.youtube.com/watch?v=u0wAgWxl52E&t=1s`,
      categories:[`mobile Project`],
      technologies: [`ReactJs`,'ReactNative','NodeJs','ExpressJs','Docker','MongoDb','axios']
    },
    {
      image: `${urlPro}assets/popfilms.png`,
      title: `popfilms`,
      description: `popfilms`,
      Demo: `https://github.com/sabirlkhaloufi/POPFILMS-reactNative`,
      LienGithub: `https://github.com/sabirlkhaloufi/POPFILMS-reactNative`,
      categories:[`mobile Project`],
      technologies: [`ReactNative`,'TMDBApi','axios']
    }
    ,
    {
      image: `${urlPro}assets/musicplayer.png`,
      title: `musicplayer`,
      description: `musicplayer`,
      Demo: `https://github.com/sabirlkhaloufi/Mucis-Player-react-native`,
      LienGithub: `https://github.com/sabirlkhaloufi/Mucis-Player-react-native`,
      categories:[`mobile Project`],
      technologies: [`ReactNative`,'api lyrics','axios']
    },
    {
      image: `${urlPro}assets/githubsearchuser.png`,
      title: `Github search user`,
      description: `ReactJs ApiGithub`,
      Demo: `https://github-user1.netlify.app/`,
      LienGithub: `https://github.com/sabirlkhaloufi/Github-search`,
      categories:[`Web Projects`],
      technologies: [`ReactJs`,'bootstrap','Github Api','Hooks','useEffect','useState','axios']
    },
    {
      image: `${urlPro}assets/electrinet.png`,
      title: `electriciens maroc`,
      description: `electriciens maroc`,
      Demo: `https://www.youtube.com/watch?v=n8aRSJLOpag&t=55s`,
      LienGithub: `https://www.youtube.com/watch?v=n8aRSJLOpag&t=55s`,
      categories:[`Web Projects`],
      technologies: [`VueJs`,'PHP','PHPMailer','ChartJs','axios']
    }
    ,
    {
      image: `${urlPro}assets/simpleportfolio.png`,
      title: `Simple Portfolio`,
      description: `Simple Portfolio`,
      Demo: `https://glittery-melba-8e28d6.netlify.app/`,
      LienGithub: `https://github.com/sabirlkhaloufi/sabirlkhaloufi.github.io`,
      categories:[`Web Projects`],
      technologies: [`Html`,'css','bootstrap','javascript']
    },
    {
      image: `${urlPro}assets/Syndicat.png`,
      title: `Syndicat Application`,
      description: `Syndicat Application`,
      Demo: `https://github.com/sabirlkhaloufi/Syndic-Application`,
      LienGithub: `https://github.com/sabirlkhaloufi/Syndic-Application`,
      categories:[`Web Projects`],
      technologies: [`ReactJs`,'MaterialUi','NodeJs','ExpressJs','MongoDb','Docker','unit testing']
    },
    {
      image: `${urlPro}assets/extension.png`,
      title: `extension google chrom NFT`,
      description: `extension google chrom NFT`,
      Demo: `https://nft-45.netlify.app/`,
      LienGithub: `https://nft-45.netlify.app/`,
      categories:[`Web Projects`],
      technologies: [`Html`,'css','javascript','manifest.json']
    }
  ]


  export default Works