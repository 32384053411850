import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { images } from "../../constants";
// import { client, urlFor } from "../../client";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const [aboutMe, setAboutMe] = useState({});
  const viewResumeHandler = () => {
    window.open("https://sabirsoft.com/cv/sabirsoft.com.pdf", "_blank");
  };

  return (
    <>
      <h2 className="head-text">
      About<span> Me</span>
      </h2>

      <div className="app__about-context app__flex">
        <div className="app__about-img app__flex">
          <div className="app__flex">
            <img
              src={
                aboutMe.profileImg
                  ? aboutMe.profileImg
                  : images.sabirImg
              }
              alt="Profile"
            />
          </div>
        </div>
        <div className="app__about-data app__flex">
          <h2 className="head-text">Sabir Lkhaloufi</h2>
          <p
            className="p-text"
            dangerouslySetInnerHTML={{ __html: "As a full stack developer, I possess a broad range of technical skills and knowledge that allow me to develop and maintain both the front-end and back-end of a web application. My expertise includes knowledge of various programming languages, databases, and frameworks, which enable me to create web applications that are not only functional but also scalable and robust." }}
          ></p>
          <div>
            <button className="portfolio-button" onClick={viewResumeHandler}>
              Resume
            </button>
          </div>
        </div>
      </div>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 1.1 }}
            transition={{ duration: 0.2, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={about.imgUrl} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: "20px" }}>
              ldkqmlkdmlskmldkd
            </h2>
            <h2 className="p-text" style={{ marginTop: "10px" }}>
             lkqjdsjqdskdjslqmkjdslmdk
            </h2>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
