const urlPro = 'https://sabirsoft.com/'
const urlDev = 'http://localhost:3000/'
const  Certificats = [
    {
      image: `${urlPro}assets/Meta-Logo.png`,
      title: `React Native specialization`,
      link: `https://coursera.org/share/6d0824e915a15b5ab8dbca7f83870a13`,
      des:""
    },
    {
      image: `${urlPro}assets/Meta-Logo.png`,
      title: `Advanced-React`,
      link: `https://coursera.org/share/d7b1009ecdb3db7421f52ad7b197af74`,
      des:""
    },
    {
      image: `${urlPro}assets/Meta-Logo.png`,
      title: `Programming-with-JavaScript`,
      link: `https://coursera.org/share/e51a1003efbd0b7182aca531e7ebf6f0`,
      des:""
    }
  ]


  export default Certificats