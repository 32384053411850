import React from "react";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import Home from "./containers/Home";
import Addfedback from "./containers/Addfedback/Addfedback";

const App = () => {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/addfedback" element={<Addfedback/>} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
