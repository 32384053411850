import React from 'react'
import './Tags.scss'
function Tags({tags}) {
  return (
    <span className='tags'>
        {tags.map((tag,index)=>(
            <a href='#' className='tag'>
            {tag}
        </a>
        ))}
        
       
    </span>
  )
}

export default Tags